import request from '@/utils/request'

const syjfApi = {
    GetSyjfRecord:'/syjf/syjf-record'
}

export function getSyjfRecord(parameter) {
  return request({
    url: syjfApi.GetSyjfRecord,
    method: 'get',
    params: parameter,
  })
}