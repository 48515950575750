//根据relate_type生成保存到oss的文件夹名称
export function getFileDir(type) {
  let result = null;
  type = type.toUpperCase();
  switch (type) {
    case "ORIGIN_COMPANY_INFO":
    case "ORIGIN_CONTACT_FILE":
      result = "contact";
      break;
    case "INTENTION_MEETING":
      result = "intention";
      break;
    case "SURVEY_INFO":
    case "SURVEY_EQUITY_STRUCTURE":
    case "SURVEY_FOUNDER":
    case "SURVEY_TEAM":
    case "SURVEY_PRODUCT":
    case "SURVEY_PRODUCT_COMPETITIVENESS":
    case "SURVEY_BUSINESS_MODEL_PURCHASE_INTENTION":
    case "SURVEY_BUSINESS_MODEL_COST_STRUCTURE":
    case "SURVEY_BUSINESS_MODEL_MARKET_STRATEGY":
    case "SURVEY_BUSINESS_MODEL_INCOME":
    case "SURVEY_BUSINESS_MODEL":
    case "SURVEY_MARKET_ANALYSIS":
    case "SURVEY_INDUSTRY_ANALYSIS":
    case "SURVEY_OPPONENT":
    case "SURVEY_OPPONENT_COMPARISON":
    case "SURVEY_FINANCE_MODEL":
    case "SURVEY_VISIT_FOUNDER":
    case "SURVEY_VISIT_STAFF":
    case "SURVEY_VISIT_CUSTOMER":
    case "SURVEY_VISIT_SUPPLIER":
    case "SURVEY_VISIT_EXPERT":
    case "SURVEY_VISIT_SCENE":
    case "SURVEY_VISIT_LAST_INVESTOR":
    case "SURVEY_VISIT_OPPONENT":
    case "SURVEY_COMPANY_ENVIRONMENT":
    case "SURVEY_FINANCE_STATEMENT":
    case "SURVEY_FINANCE_REPORT":
    case "SURVEY_ATTACHMENT":
      result = "due_diligence";
      break;
    case "ORIGIN_INVESTMENT_PROPOSALS":
    case "ORIGIN_RISK_REPORT":
    case "ORIGIN_MEETING_RECORD":
    case "ORIGIN_VOTE_FILE":
    case "ORIGIN_PASS_MEETING":
      result = "meeting";
      break;
    case "DYNAMIC_DEVELOP":
    case "DYNAMIC_TEAM":
    case "DYNAMIC_FINANCE":
    case "DYNAMIC_OPPONENT":
      result = "dynamic";
      break;
    case "ORIGIN_PAYMENT_FILE":
    case "FINANCE_RECORD":
    case "COMPANY_FINANCE_REPORT":
    case "INVESTMENT_PLAN":
      result = "company_finance";
      break;
    case "COMPANY_RISK_EVENT":
    case "COMPANY_RISK_EVENT_RESULT":
    case "COMPANY_TERM":
      result = "risk_manage";
      break;
    case "EXIT_PROJECT":
      result = "withdrawal";
      break;
    case "FUND_REPORT":
      result = "fund";
      break;
    case "LIMITED_PARTNER":
    case "LP_RECORD":
      result = "partner";
      break;
    case "FEEDBACK":
    case "FEEDBACK_REPLY":
    case "USER_AVATAR":
      result = "user";
      break;
    case "REPORT_TEMPLATE_RISK_CONTROL":
    case "REPORT_INVESTMENT_PROPOSAL":
    case "REPORT_NON_DISCLOSURE_AGREEMENT":
    case "REPORT_POST_INVESTMENT":
    case "REPORT_RISK_CONTROL":
    case "REPORT_TERM_SHEET":
    case "REPORT_AUXILIARY":
    case "REPORT_FUND":
      result = "report";
      break;
    case "SYJF_REWARD":
      result = "reward";
      break;
    case "SYJF_LICENSE":
      result = "license";
      break;
    case "SYJF_STANDARD":
      result = "standard";
      break;
    case "SYJF_TECH_QUALIFICATION":
      result = "hightech";
      break;
    case "VPN_QRCODE":
      result = "vpn";
      break;
  }
  return result;
}

export function getExtensionsFromFileName(fileName) {
  if (fileName == null) return null;
  let names = fileName.split(".");
  if (names.length > 1) {
    return names[names.length - 1];
  } else {
    return "";
  }
}

export function getExtensionsFromMimeType(mimeType) {
  switch (mimeType) {
    case "application/msword":
      return "doc";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "docx";
    case "application/pdf":
      return "pdf";
    case "application/vnd.ms-powerpoint":
      return "ppt";
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return "pptx";
    case "text/csv":
      return "csv";
    case "application/vnd.ms-excel":
      return "xls";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "xlsx";
    case "application/zip":
      return "zip";
    case "application/x-rar-compressed":
      return "rar";
    case "image/bmp":
      return "bmp";
    case "image/jpeg":
      return "jpg";
    case "image/png":
      return "png";
    case "audio/aac":
      return "aac";
    case "application/x-abiword":
      return "abw";
    case "application/x-freearc":
      return "arc";
    case "video/x-msvideo":
      return "avi";
    case "application/vnd.amazon.ebook":
      return "azw";
    case "application/octet-stream":
      return "bin";
    case "application/x-bzip":
      return "bz";
    case "application/x-bzip2":
      return "bz2";
    case "application/x-csh":
      return "csh";
    case "text/css":
      return "css";
    case "application/vnd.ms-fontobject":
      return "eot";
    case "application/epub+zip":
      return "epub";
    case "image/gif":
      return "gif";
    case "text/html":
      return "html";
    case "image/vnd.microsoft.icon":
      return "ico";
    case "text/calendar":
      return "ics";
    case "application/java-archive":
      return "jar";
    case "text/javascript":
      return "js";
    case "application/json":
      return "json";
    case "application/ld+json":
      return "jsonld";
    case "audio/midi":
    case "audio/x-midi":
      return "midi";
    case "audio/mpeg":
      return "mp3";
    case "video/mpeg":
      return "mpeg";
    case "application/vnd.apple.installer+xml":
      return "mpkg";
    case "application/vnd.oasis.opendocument.presentation":
      return "odp";
    case "application/vnd.oasis.opendocument.spreadsheet":
      return "ods";
    case "application/vnd.oasis.opendocument.text":
      return "odt";
    case "audio/ogg":
      return "oga";
    case "video/ogg":
      return "ogv";
    case "application/ogg":
      return "ogx";
    case "font/otf":
      return "otf";
    case "application/rtf":
      return "rtf";
    case "application/x-sh":
      return "sh";
    case "image/svg+xml":
      return "svg";
    case "application/x-shockwave-flash":
      return "swf";
    case "application/x-tar":
      return "tar";
    case "image/tiff":
      return "tiff";
    case "font/ttf":
      return "ttf";
    case "text/plain":
      return "txt";
    case "application/vnd.visio":
      return "vsd";
    case "audio/wav":
      return "wav";
    case "audio/webm":
      return "weba";
    case "video/webm":
      return "webm";
    case "image/webp":
      return "webp";
    case "font/woff":
      return "woff";
    case "font/woff2":
      return "woff2";
    case "application/xhtml+xml":
      return "xhtml";
    case "text/xml":
    case "application/xml":
      return "xml";
    case "application/vnd.mozilla.xul+xml":
      return "xul";
    case "video/3gpp":
    case "audio/3gpp":
      return "3gp";
    case "video/3gpp2":
    case "audio/3gpp2":
      return "3g2";
    case "application/x-7z-compressed":
      return "7z";
  }
}
