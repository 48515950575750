<template>
  <div>
    <b class="title"
      >文件上传仅支持.xls、.xlsx、.csv(utf-8)格式文件，上传完成后系统自动于后台处理文件数据</b
    >
    <div class="btn-area" v-if="hasAction('syjf-upload-file')">
      <upload-syjf-file-to-oss
        type="SYJF_TECH_QUALIFICATION"
        @upload="loadData"
      >
        <a-button size="large" slot="btn"
          ><i class="iconfont icon-keji icon" />科技资质<a-icon type="upload"
        /></a-button>
      </upload-syjf-file-to-oss>
      <upload-syjf-file-to-oss type="SYJF_LICENSE" @upload="loadData">
        <a-button size="large" slot="btn"
          ><i class="iconfont icon-zhengzhao icon" />专项证照<a-icon
            type="upload"
        /></a-button>
      </upload-syjf-file-to-oss>
      <upload-syjf-file-to-oss type="SYJF_REWARD" @upload="loadData">
        <a-button size="large" slot="btn"
          ><i class="iconfont icon-shuyi_zhengfu icon" />政府奖励<a-icon
            type="upload"
        /></a-button>
      </upload-syjf-file-to-oss>
      <upload-syjf-file-to-oss type="SYJF_STANDARD" @upload="loadData">
        <a-button size="large" slot="btn"
          ><i class="iconfont icon-biaozhunwuyehetong icon" />标准制定<a-icon
            type="upload"
        /></a-button>
      </upload-syjf-file-to-oss>
    </div>
    <a-card class="filter-area" v-if="hasAction('syjf-file-list')">
      <div slot="title">
        <div style="display: inline-block; vertical-align: middle">
          <div style="display: flex">
            <div style="width: 4.5rem; font-size: 14px; line-height: 32px">
              上传类型：
            </div>
            <div style="flex: 1">
              <a-select
                :value="option.type"
                style="width: 10rem"
                @change="(value) => (option.type = value)"
                placeholder="请选择上传类型"
              >
                <a-select-option
                  v-for="typeItem in types"
                  :key="typeItem.value"
                  :value="typeItem.value"
                  >{{ typeItem.title }}</a-select-option>
              </a-select>
            </div>
          </div>
        </div>
        <div
          style="
            display: inline-block;
            margin-left: 20px;
            vertical-align: middle;
          "
        >
          <div style="display: flex">
            <div style="width: 4.5rem; font-size: 14px; line-height: 32px">
              上传时间：
            </div>
            <div style="flex: 1">
              <a-date-picker
                :value="option.uploadDate.start"
                @change="(value) => (option.uploadDate.start = value)"
                style="width: 8rem"
              />
              <span style="padding: 0 10px; font-size: 14px">至</span>
              <a-date-picker
                :value="option.uploadDate.end"
                @change="(value) => (option.uploadDate.end = value)"
                style="width: 8rem"
              />
            </div>
          </div>
        </div>
        <div style="display: inline-block; vertical-align: middle">
          <a-button type="primary" class="filter-btn" @click="handleFilter"
            >筛选</a-button
          >
          <a-button class="filter-btn" @click="resetFilter">重置</a-button>
        </div>
      </div>
      <div>
        <a-list
          item-layout="horizontal"
          :pagination="pagination"
          :data-source="fileList"
          v-if="fileList.length > 0"
          :loading="loading"
        >
          <a-list-item slot="renderItem" slot-scope="item">
            <a-list-item-meta>
              <span slot="description" style="font-size: 12px">
                <span
                  >上传时间：{{
                    moment(item.uploadTime).format("YYYY-MM-DD LTS")
                  }}</span
                >
                <span style="padding-left: 20px" v-if="item.startTime"
                  >开始处理时间：{{
                    moment(item.startTime).format("YYYY-MM-DD LTS")
                  }}</span
                >
                <span style="padding-left: 20px" v-if="item.endTime"
                  >处理完成时间：{{
                    moment(item.endTime).format("YYYY-MM-DD LTS")
                  }}</span
                >
              </span>
              <a
                slot="title"
                style="color: #40a9ff"
                @click="download(item.fileId)"
                >{{ item.fileName }}</a
              >
              <div
                slot="avatar"
                style="
                  display: inline-block;
                  text-align: center;
                  width: 48px;
                  height: 48px;
                "
              >
                <i
                  class="iconfont icon-keji avatar"
                  v-if="item.fileType == 'SYJF_TECH_QUALIFICATION'"
                />
                <i
                  class="iconfont icon-zhengzhao"
                  style="font-size: 1.5rem; line-height: 3rem"
                  v-else-if="item.fileType == 'SYJF_LICENSE'"
                />
                <i
                  class="iconfont icon-shuyi_zhengfu avatar"
                  v-else-if="item.fileType == 'SYJF_REWARD'"
                />
                <i
                  class="iconfont icon-biaozhunwuyehetong avatar"
                  v-else-if="item.fileType == 'SYJF_STANDARD'"
                />
              </div>
            </a-list-item-meta>
            <div style="margin-right:20px">
              <span v-if="item.status == 'SUCCESS'">
                <span
                  v-if="item.detail.allFailNum > 0"
                  style="color: #ee6666; padding-right: 10px"
                >
                  {{ item.detail.allFailNum }}
                  <a-tooltip>
                    <div slot="title">
                      <div v-if="item.detail.companyNotExistErrorNum">
                        因公司不存在而入库失败<span style="color: #40a9ff">{{
                          item.detail.companyNotExistErrorNum
                        }}</span
                        >条
                      </div>
                      <div v-if="item.detail.emptyFieldErrorNum">
                        因关键信息缺失入库失败<span style="color: #40a9ff">{{
                          item.detail.emptyFieldErrorNum
                        }}</span
                        >条
                      </div>
                      <div v-if="item.detail.otherErrorNum">
                        因系统错误入库失败<span style="color: #40a9ff">{{
                          item.detail.otherErrorNum
                        }}</span
                        >条
                      </div>
                    </div>
                    <a-icon
                      type="info-circle"
                      theme="filled"
                      style="color: #ee6666"
                    />
                  </a-tooltip>
                </span>
                <span style="color: #91cc75">{{ item.detail.successNum }}</span>
                <span>/{{ item.detail.allNum }}</span>
              </span>
              <span v-else-if="item.status == 'FAIL'">
                <a-tooltip>
                  <template slot="title">
                    文件表头与上传类型要求不匹配
                  </template>
                  <a-icon
                    type="info-circle"
                    theme="filled"
                    style="color: #ee6666"
                  />
                </a-tooltip>
              </span>
            </div>
            <span slot="extra" style="cursor:default">
              <span v-if="item.status == 'WAITING'"
                ><a-icon
                  type="history"
                  style="font-size: 1.2rem; padding-right: 5px"
                />等待处理</span
              >
              <span
                v-else-if="item.status == 'PROCESSING'"
                style="color: #40a9ff"
                ><a-icon
                  type="loading"
                  style="font-size: 1.2rem; padding-right: 5px"
                />正在处理</span
              >
              <span v-else-if="item.status == 'SUCCESS'" style="color: #91cc75"
                ><a-icon
                  type="smile"
                  style="font-size: 1.2rem; padding-right: 5px"
                />处理完成</span
              >
              <span v-else-if="item.status == 'FAIL'" style="color: #ee6666"
                ><a-icon
                  type="frown"
                  style="font-size: 1.2rem; padding-right: 5px"
                />处理失败</span
              >
            </span>
          </a-list-item>
        </a-list>
        <a-empty v-else />
      </div>
    </a-card>
  </div>
</template>

<script>
import UploadSyjfFileToOss from "./components/UploadSyjfFileToOss.vue";
import { getOssDownloadURL } from "@/api/file";
import { getSyjfRecord } from "@/api/syjf.js";
import moment from "moment";
import "@/assets/iconfont/iconfont.css";
export default {
  name: "Index",
  components: {
    UploadSyjfFileToOss,
  },
  props: {},
  data() {
    return {
      option: {
        type: undefined,
        uploadDate: {
          start: null,
          end: null,
        },
      },
      formData: {},
      types: [
        { title: "科技资质", value: "SYJF_TECH_QUALIFICATION" },
        { title: "专项证照", value: "SYJF_LICENSE" },
        { title: "政府奖励", value: "SYJF_REWARD" },
        { title: "标准制定", value: "SYJF_STANDARD" },
      ],
      fileList: [],
      pagination: {
        pageSize: 6,
      },
      loading: true,
      stoId: null,
    };
  },
  created() {
    this.loadData();
  },
  beforeDestroy() {
    this.stoId && clearTimeout(this.stoId);
  },
  computed: {
    allHandled() {
      if (this.fileList == null || this.fileList.length == 0) return true;
      return (
        this.fileList.filter(
          (item) => item.status != "SUCCESS" && item.status != "FAIL"
        ).length == 0
      );
    },
  },
  methods: {
    moment,
    loadData() {
      getSyjfRecord(this.formData)
        .then((res) => {
          this.fileList = res.data;
          this.fileList.forEach((item) => {
            item.detail = item.detail != null ? JSON.parse(item.detail) : null;
          });
          // console.log(this.allHandled);
          // 如果文件列表中的文件未全部处理完毕
          if (!this.allHandled) {
            // 如果已存在定时器
            this.stoId && clearTimeout(this.stoId);
            this.stoId = setTimeout(() => {
              this.loadData();
            }, 5000); // v1.9.1 设置时间为 5s
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleFilter() {
      this.stoId && clearTimeout(this.stoId);
      this.loading = true;
      this.formData = {
        type: this.option.type || null,
        begin: this.option.uploadDate.start
          ? moment(this.option.uploadDate.start).format("YYYY-MM-DD")
          : null,
        end: this.option.uploadDate.end
          ? moment(this.option.uploadDate.end).format("YYYY-MM-DD")
          : null,
      };
      this.loadData();
    },
    resetFilter() {
      this.stoId && clearTimeout(this.stoId);
      this.loading = true;
      this.option = {
        type: undefined,
        uploadDate: {
          start: null,
          end: null,
        },
      };
      this.formData = {};
      this.loadData();
    },
    download(fileId) {
      if (this.hasAction("download-file")) {
        getOssDownloadURL({ fileId, protocol:window.location.protocol }).then((res) => {
          window.open(res.data, "_blank");
        });
      } else {
        this.$message.error("无下载权限！");
      }
    },
  },
};
</script>

<style scoped>
.title {
  line-height: 30px;
  font-size: 15px;
  color: #f72c5b;
}
.btn-area {
  display: flex;
  justify-content: space-between;
  padding: 50px 100px 10px 100px;
}
.icon {
  padding-right: 10px;
}
.filter-area {
  margin: 40px 100px;
}
.filter-btn {
  width: 6rem;
  margin-left: 2rem;
}
.avatar {
  font-size: 2rem;
}
.ant-list >>> .ant-list-item-meta-avatar {
  margin-right: 10px;
  margin-left: 10px;
}
</style>