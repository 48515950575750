<template>
  <div>
    <a-upload :custom-request="handleUpload" :showUploadList="false">
      <slot name="btn"></slot>
    </a-upload>
    <a-modal
      :visible="visible"
      :maskClosable="false"
      :closable="false"
      :footer="null"
      :width="250"
      :centered="true"
    >
      <a-spin style="margin-right: 10px" />
      <span>文件正在上传中，请稍后...</span>
    </a-modal>
  </div>
</template>

<script>
import { getSyjfUploadAccess } from "@/api/file";
import { getFileDir, getExtensionsFromFileName } from "@/utils/upload-utils";
import { uuid } from "@/utils/uuid";
import axios from "axios";

export default {
  name: "UploadSyjfFileToOss",
  components: {},
  props: {
    type: {
      //文件类型
      type: String,
      require: true,
    },
  },
  data() {
    return {
      signature: {
        accessId: "",
        signature: "",
        host: "",
        policy: "",
        callback: "",
        expire: "",
        dir: "",
      },
      limitType: ["csv", "xls", "xlsx"],
      visible: false,
    };
  },
  methods: {
    handleUpload(files) {
      let Files = new FormData();
      let file = files.file;
      if (!this.limitType.includes(getExtensionsFromFileName(file.name))) {
        this.$message.error(
          `${file.name}上传格式错误，仅支持 .csv、.xls、.xlsx 格式`
        );
        return;
      }
      this.visible = true;
      let fileName = `${getFileDir(
        this.type
      )}/${uuid().toLowerCase()}.${getExtensionsFromFileName(file.name)}`;
      let now = Date.parse(new Date()) / 1000;
      if (
        this.signature.expire === "" ||
        parseInt(this.signature.expire) < now + 3
      ) {
        // 判断签名是否到期
        return getSyjfUploadAccess({urlPrefix:`${window.location.protocol}//`}).then((res) => {
          // debugger
          if (res.code === 0) {
            this.signature = res.data;
            Files = this.setFileValue(Files, fileName, file);
            return this.fileUpload(Files, this.signature.host, file.name);
          }
        });
      } else {
        Files = this.setFileValue(Files, fileName, file);
        return this.fileUpload(Files, this.signature.host, file.name);
      }
    },
    setFileValue(Files, fileName, file) {
      Files.set("key", `${this.signature.dir}${fileName}`); //key 唯一值  即相对路径
      Files.set("callback", this.signature.callback); // callback不能放在file属性下面，会被作为file的一部分
      Files.set("policy", this.signature.policy); //服务器返回的policy
      Files.set("OSSAccessKeyId", this.signature.accessId); //服务器返回的accessId
      Files.set("success_action_status", "200"); //定义成功为200
      Files.set("signature", this.signature.signature); //服务器返回的signature
      Files.set("name", fileName); //上传到oss的文件名
      Files.set(
        "Content-Disposition",
        'attachment;filename="' + file.name + '"'
      ); //设置下载时的文件名
      Files.set("x:old_name", file.name); //自定义参数，携带文件原本名字
      Files.set("x:relate_type", this.type.toUpperCase()); //自定义参数，携带文件类型
      Files.set("x:relate_id", 0); //自定义参数，携带上传id
      Files.set("x:upload_time", new Date().getTime()); //自定义参数，携带上传时间
      //file参数要放到最下面，否则其他参数会被认为是file的一部分
      Files.set("file", file, fileName); //文件对象
      return Files;
    },
    async fileUpload(Files, url, fileOriginName) {
      let res = await axios
        .post(url, Files, {
          //发送请求的url就是服务器返回的host
          "Content-Type": "multipart/form-data",
          withCredentials: false,
          timeout: 5 * 60 * 1000, // 设置超时时间5分钟
        })
        .catch((err) => {
          if (err.toString().split(" ").includes("timeout")) {
            this.$notification.error({
              message: `${fileOriginName}上传失败`,
              description: '上传超时，请检查您的网络情况，稍后重新上传',
            })
          } else {
            this.$notification.error({
              message: `${fileOriginName}上传失败`,
              description: err.toString(),
            })
          }
          this.visible = false;
        });
      // debugger
      if (res != null) {
        if (res.data.Status != "OK") {
          this.$notification.error({
            message: `${fileOriginName}上传失败`,
            description: `上传校验不通过`,
          })
        } else {
          this.$message.success(`${fileOriginName}上传成功！`);
        }
        this.$emit("upload");
        this.visible = false;
      }
    },
  },
};
</script>

<style scoped>
</style>