import request from '@/utils/request'

const fileApi = {
    GetFiles: '/file/list',
    GetUploadAccess: '/oss/upload-access',
    GetSyjfUploadAccess:'/oss/syjf-upload-access',
    GetOssDownloadURL:'/oss/download-url',
    DeleteOssFile:'/oss/oss-delete',
    GetFileListById:'/oss/list-by-id',
    GetFilePreview:'/oss/preview-urls',
    GetFileList: '/oss/list',
    GetFileListBatchByGroup: '/oss/list-by-group',
}

export function getFiles(parameter) {
    return request({
      url: fileApi.GetFiles,
      method: 'get',
      params: parameter
    })
}

export function getUploadAccess(parameter) {
  return request({
    url: fileApi.GetUploadAccess,
    method: 'get',
    params: parameter
  })
}

export function getSyjfUploadAccess(parameter) {
  return request({
    url: fileApi.GetSyjfUploadAccess,
    method: 'get',
    params: parameter
  })
}

export function getOssDownloadURL(parameter) {
  return request({
    url: fileApi.GetOssDownloadURL,
    method: 'get',
    params: parameter
  })
}

export function deleteOssFile(parameter) {
  return request({
    url: fileApi.DeleteOssFile,
    method: 'get',
    params: parameter
  })
}

export function getFileListById(parameter) {
  return request({
    url: fileApi.GetFileListById,
    method: 'get',
    params: parameter
  })
}

export function getFilePreview(parameter) {
  return request({
    url: fileApi.GetFilePreview,
    method: 'get',
    params: parameter
  })
}

export function getFileList(parameter) {
  return request({
    url: fileApi.GetFileList,
    method: 'get',
    params: parameter
  })
}

export function getFileListBatchByGroup(parameter) {
  return request({
    url: `${fileApi.GetFileListBatchByGroup}?groupId=${parameter.groupId}`,
    method:'post',
    data: parameter.fileRelateVos,
  })
}